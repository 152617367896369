




<p-dialog header="Title" [(visible)]="display" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" *ngIf="display">
    <ng-template pTemplate="header">
       <h2>
        Agregar Escuela
       </h2> 
    </ng-template>
    <div class="col">
        <form action="" [formGroup]="formFood">
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Nombre de la escuela</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="name" name="name" #name (input)="getName()"  >
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Email </label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="login_email" name="email" (input)="getEmail()" #email>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">contraseña </label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="password" name="password" >
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Pagina web</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="url" name="url" #url  (input)="getUrl()">
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Direccion</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="address" name="address"  #address (input)="getAddress()">
                </div>         
            </div>
            <div class="row mb-4">
                <div class="col-3">
                    
                </div>
                <div class="form-check col-3">
                    <input class="form-check-input" type="checkbox"  formControlName="active" name="active" id="flexRadioDefault2" >
                    <label class="form-check-label" for="flexRadioDefault2">
                        active
                    </label>
                  </div>
                  <div class="form-check col-3">
                    <input class="form-check-input" type="checkbox" formControlName="verify" name="verify" id="flexRadioDefault3"  >
                    <label class="form-check-label" for="flexRadioDefault3">
                        verify
                    </label>
                  </div>     
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Nombre Contacto 1</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact" name="contact" (input)="getContact()" #contact>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Email contacto 1:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_first_email" name="contact_first_email"  (input)="getContact_second_email()" #contact_second_email>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Teléfono contacto 1:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="phone" name="phone" (input)="getPhone()" #phone>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Celular contacto 1:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="cellphone" name="cellphone" (input)="getCellphone()" #cellphone>
                </div>         
            </div>
        
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Nombre Contacto 2</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_second_name" name="contact_second_name" (input)="getContact_second_name()" #contact_second_name>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Teléfono contacto 2:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_second_phone" name="contact_second_phone" (input)="getContact_second_phone()" #contact_second_phone>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Celular contacto 2:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_second_cellphone" name="contact_second_cellphone" (input)="getContact_second_cellphone()" #contact_second_cellphone>
                </div>         
            </div>
           
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Email contacto 2:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_second_email" name="contact_second_email"  (input)="getContact_second_email()" #contact_second_email>
                </div>         
            </div>

            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Nombre Contacto 3:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_third_name" name="contact_third_name"  (input)="getContact_third_name()" #contact_third_name>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Teléfono contacto 3:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_third_phone" name="contact_third_phone" (input)="getContact_third_phone()" #contact_third_phone>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Celular contacto 3:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_third_cellphone" name="contact_third_cellphone" (input)="getContact_third_cellphone()" #contact_third_cellphone>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Email contacto 3:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_third_email" name="contact_third_email" (input)="getContact_third_email()" #contact_third_email>
                </div>         
            </div>
           
        </form>
        <div class="alert alert-primary" role="alert" *ngIf="statuAgrgado">
            Se Agrego correctamente
          </div>

          
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="guardar()" >Guardar</button>
    </ng-template>
</p-dialog>
<p-dialog header="Title" [(visible)]="display2" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" *ngIf="display2">
    <ng-template pTemplate="header">
       <h2>
        Editar  Escuela
       </h2>
      
    </ng-template>
    <div class="col">
        <form action="" [formGroup]="formFood2">
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Nombre de la escuela</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="name" name="name" #name   >
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Email </label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="login_email" name="email"   #email>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">contraseña </label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="password" name="password" >
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Pagina web</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="url" name="url" #url   >
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Direccion</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="address" name="address"  #address (input)="getAddress()">
                </div>         
            </div>
            <div class="row mb-4">
                <div class="col-3">
                    
                </div>
                <div class="form-check col-3">
                    <input class="form-check-input" type="checkbox"  formControlName="active" name="active" id="flexRadioDefault2" >
                    <label class="form-check-label" for="flexRadioDefault2">
                        active
                    </label>
                  </div>
                  <div class="form-check col-3">
                    <input class="form-check-input" type="checkbox" formControlName="verify" name="verify" id="flexRadioDefault3"  >
                    <label class="form-check-label" for="flexRadioDefault3">
                        verify
                    </label>
                  </div>     
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Nombre Contacto 1</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact" name="contact" (input)="getContact()" #contact>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Email contacto 1:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_first_email" name="contact_first_email"  (input)="getContact_second_email()" #contact_second_email>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Teléfono contacto 1:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="phone" name="phone" (input)="getPhone()" #phone>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Celular contacto 1:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="cellphone" name="cellphone" (input)="getCellphone()" #cellphone>
                </div>         
            </div>
        
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Nombre Contacto 2</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_second_name" name="contact_second_name" (input)="getContact_second_name()" #contact_second_name>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Teléfono contacto 2:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_second_phone" name="contact_second_phone" (input)="getContact_second_phone()" #contact_second_phone>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Celular contacto 2:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_second_cellphone" name="contact_second_cellphone" (input)="getContact_second_cellphone()" #contact_second_cellphone>
                </div>         
            </div>
           
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Email contacto 2:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_second_email" name="contact_second_email"  (input)="getContact_second_email()" #contact_second_email>
                </div>         
            </div>

            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Nombre Contacto 3:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_third_name" name="contact_third_name"  (input)="getContact_third_name()" #contact_third_name>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Teléfono contacto 3:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_third_phone" name="contact_third_phone" (input)="getContact_third_phone()" #contact_third_phone>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Celular contacto 3:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_third_cellphone" name="contact_third_cellphone" (input)="getContact_third_cellphone()" #contact_third_cellphone>
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Email contacto 3:</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="contact_third_email" name="contact_third_email" (input)="getContact_third_email()" #contact_third_email>
                </div>         
            </div>
           
        </form>
        <div class="alert alert-primary" role="alert" *ngIf="statuAgrgado">
            Se Agrego correctamente
          </div>

          
    </div>
   

    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal2()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="keepUpdate()">Guardar</button>
    </ng-template>
</p-dialog>

<p-dialog header="Title" [(visible)]="display3" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
       <h2>
        Eliminar  Escuela
       </h2> 
    </ng-template>
    <div class="col">
       {{TextElimint}}
        <div class="alert alert-danger" role="alert" *ngIf="statuAgrgado">
            Se elimino correctamente
          </div>

          
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="this.display3 = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="delet()">Eliminar</button>
    </ng-template>
</p-dialog>
<p-tabView>
    <p-tabPanel header="Escuelas">
        <div>

            <div class="card border border-info border-1">
                <div class="bg-info p-2">
                    <h1 class="card-title text-white">Lista de Escuela</h1>
                </div>
                <div class="card-body">
                <div class="container-btn mt-1 mb-3">
                    <div>
                        <div ngbDropdown placement="top-end" class="d-inline-block" style="margin-right: 10px;">
                            <button type="button" class="btn btn-primary" (click)="showDialog()">Agregar nueva escuela</button>
        
                        </div>
                    </div>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Global Search" />
                </div>
        <div style="overflow: auto;">
            <p-table #dt [value]="listcatalogos" [rows]="10"
            [globalFilterFields]="['name','name','email','contact_second_email','contact_third_name','acronyms']"
            [(selection)]="selectCatalogos" [rowHover]="true" dataKey="id"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true">

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="name">Nombre<p-sortIcon field="name"
                            style="font-size: 10px;"></p-sortIcon></th>
                    <th pSortableColumn="email">Email contact 1<p-sortIcon field="email"></p-sortIcon></th>
                    <th pSortableColumn="contact_second_email">Email contact 2<p-sortIcon field="contact_second_email"></p-sortIcon></th>
                    <th pSortableColumn="contact_third_name">Email contact 3<p-sortIcon field="contact_third_name"></p-sortIcon></th>
                    <th pSortableColumn="assigned_id">Verificado</th>
                    <th pSortableColumn="assigned_id">Activo</th>


                    <th style="width: 40px;"></th>
                    <th style="width: 40px;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr>
                    <td>
                        {{customer.name}}
                    </td>
                    <td>
                        {{customer.email}}
                    </td>
                    <td>
                        {{customer.contact_second_email}}
                    </td>
                    <td>
                        {{customer.contact_third_email}}
                    </td>
                    <td>
                        <i class="bx bxs-calendar-check" *ngIf="customer.verify" style="color: green;"></i> <i class="bx bxs-calendar-x" style="color: red;" *ngIf="!customer.verify"></i>
                    </td>
                    <td>
                        <i class="bx bxs-calendar-check" *ngIf="customer.active" style="color: green;"></i> <i class="bx bxs-calendar-x" style="color: red;" *ngIf="!customer.active"></i>
                    </td>
                   
                   
                    <td>
                        <button class="btn btn-warning" (click)="update(customer)">Editar</button>

                    </td>
                    <td>
                        <button class="btn btn-danger" (click)="deletModal(customer.name,customer.id)">Eliminar</button>
                    </td>
                 
                </tr>
            </ng-template>

        </p-table>
        </div>
                
            </div>
            </div>
        
        
        
        
        
        </div>
        
    </p-tabPanel>
    
  
</p-tabView>

