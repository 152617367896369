
<div *ngIf="cargando">
  <div class="card text-center p-2">
    <h2>Tienes un saldo pendiente de :</h2>
    <ul>
      <li *ngFor="let currency of (totalGeneralBalance | keyvalue)" style="list-style-type:none">
        <h4> {{ currency.key }}: {{ currency.value | currency:currency.key:'symbol-narrow' }} </h4>
      </li>
    </ul>
  </div>

 
</div>
<div class="row" *ngIf="cargando">
  <div class="col-xl-6 col-sm-12" *ngFor="let data of hijosRes">
    <div class="card text-center">
      <div class="card-body">
        <!-- Foto o inicial del camper -->
        <div class="avatar-sm mx-auto mb-4" *ngIf="!data.camper.photo" (click)="redirectPerfil(data.camper.id)" style="cursor: pointer;">
          <a class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16">
            {{ data.camper.full_name.charAt(0) }}
          </a>
        </div>
        <div *ngIf="data.camper.photo" class="mb-4" (click)="redirectPerfil(data.camper.id)" style="cursor: pointer;">
          <img class="rounded-circle avatar-sm" [src]="'http://142.93.12.234:8000/' + data.camper.photo" alt   style="  transform: rotate(90deg); /* Rotar 90 grados */
 "
          />
        </div>

        <!-- Información del camper -->
        <h5 class="font-size-15 mb-1"><a [routerLink]="['/dashboard/parents/camper/' + data.camper.id]"  class="text-dark">{{ data.camper.full_name }}</a></h5>
        <p class="text-muted">{{ data.camper.school }}</p>

        <!-- Balance por moneda -->
        <h6>Balances por Moneda:</h6>
        <ul style="list-style-type:none">
          <li *ngFor="let currency of (data.currencyBalances | keyvalue)">
            {{ currency.key }}: {{ currency.value | currency:currency.key:'symbol-narrow' }}
          </li>
        </ul>

        <!-- Total general del camper -->

        <!-- Campamentos -->
        <div class="col-12 card-info" style="margin: 0 auto; cursor: pointer;">
          <div class="col-12 center-ifno" *ngFor="let a of data.camps" (click)="redirect(a.camp_id, data.camper.id)">
            <a class="font-size-15 camps"   [routerLink]="['/dashboard/parents/camp-info', data.camper.id, a.camp_id]"
            >
              {{ a.camp_name }}:
              <small
                class="badge font-size-11"
                [ngClass]="{ 'bg-warning': a.camper_payment_balance > 0, 'bg-success': a.camper_payment_balance <= 0 }"
                *ngIf="a.show_payment_parent">
                {{ a.currency_symbol }} {{ a.camper_payment_balance | currency:'':'':'1.0-0' }} {{ a.currency_acronyms }}
              </small>
            </a>
          </div>



          <div class="col-12 center-ifno" *ngFor="let a of data.due_past_camps" (click)="redirect(a.camp_id, data.camper.id)">
            <a class="font-size-15 camps"   [routerLink]="['/dashboard/parents/camp-info', data.camper.id, a.camp_id]"
            >
              {{ a.camp_name }}:
              <small
                class="badge font-size-11"
                [ngClass]="{ 'bg-warning': a.camper_payment_balance > 0, 'bg-success': a.camper_payment_balance <= 0 }"
                *ngIf="a.show_payment_parent">
                {{ a.currency_symbol }} {{ a.camper_payment_balance | currency:'':'':'1.0-0' }} {{ a.currency_acronyms }}
              </small>
            </a>
          </div>
        </div>

        <!-- Botones -->
        <div style="margin: 0 auto;">
          <hr />
          <a [routerLink]="['/dashboard/parents/inscription/' + data.camper.id]" class="btn btn-success" style="width: 90%;">
            {{ textos['cardBotton'] }}
          </a>
        </div>
      </div>
      <div class="card-footer bg-transparent border-top">
        <div class="contact-links d-flex font-size-20">
          <div class="flex-fill">
            <a [routerLink]="['/dashboard/parents/update-camper/' + data.camper.id]" ngbTooltip="Editar informacion"><i class="bx dripicons-document-edit"></i></a>
          </div>
          <div class="flex-fill">
            <a [routerLink]="['/dashboard/parents/camper/' + data.camper.id]" ngbTooltip="Perfil"><i class="bx bx-user-circle"></i></a>
          </div>
          <div class="flex-fill">
            <a [routerLink]="['/dashboard/parents/inscription/' + data.camper.id]" ngbTooltip="Inscribir"><i class="mdi mdi-account-arrow-right-outline"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
